<template>
  <div class="noData">
    <img src="@/assets/imgs/nodata.png" width="339" alt />
    <p style="font-size: 14px;margin-top:30px;color: #606978;">
      请
      <span class="login_txt" @click="toLogin">登录</span>哦！！！
    </p>
  </div>
</template>

<script>
import { Login } from "@/assets/js/login.js";

export default {
  name: "noData",
  props: {},
  data() {
    return {};
  },
  methods: {
    toLogin() {
      Login();
    },
  },
};
</script>

<style lang="scss" scoped>
.noData {
  text-align: center;
  padding: 120px 0;
}
.login_txt {
  color: #5d5aed;
  cursor: pointer;
}
</style>