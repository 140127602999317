//������ѯ
import request from '@/utils/http.js';

//��ѯ�γ�
export function queryCoursePage(options) {
    return request({
        url: '/businessPro/queryCoursePage',
        method: 'post',
        data: options
    })
}

//��ѯ����
export function queryMatchPage(options) {
    return request({
        url: '/businessPro/queryMatchPage',
        method: 'post',
        data: options
    })
}

//��ѯѫ��
export function queryMedalPage(options) {
    return request({
        url: '/businessPro/queryMedalPage',
        method: 'post',
        data: options
    })
}

//��ѯ��Ѷ
export function queryNewsPage(options) {
    return request({
        url: '/businessPro/queryNewsPage',
        method: 'post',
        data: options
    })
}

