<template>
    <div id="Search" style="background-color: #F8F9FA;">
        <div class="Main">
            <div class="topInput">
                <a-input placeholder="请输入搜索内容" v-model="SKeyWord" @keydown="enter"/>
                <a-button type="primary" @click="searchData" ><img src="../assets/imgs/search2.png">搜索</a-button>
            </div>
            <div class="content">
                <a-tabs v-model="activeKey">
                    <a-tab-pane 
                    :key="index" 
                    :tab="`${item.moduleNameUser}(${item.total == undefined ? 0 : item.total})`"
                    v-for="(item,index) in PortalConfig.searchMenuList">
                        <div class="tab_cont" v-if="item.moduleCode == MODELCODE.CLASSES">
                            <div v-if="courseList&&courseList.length && isLogin">
                                <ul class="course" >
                                    <li v-for='course in  courseList' :key="course.id" @click="goCourse(course.courseId)">
                                        <h2><span>{{course.difficultyLevel==0?'初级':course.difficultyLevel==1?'中级':'高级'}}</span>{{course.name}}</h2>
                                        <h3>{{course.taskCount}}课时</h3>
                                        <div class="teacher">
                                        <img v-if="course.teachers!=null&&course.teachers.length>0&&course.teachers.length<3" :src="QiNiuPath+course.teachers[0].pic">
                                        <img v-if="course.teachers!=null&&course.teachers.length>1&&course.teachers.length<3" :src="QiNiuPath+course.teachers[1].pic"> 
                                        <div class="headgroup"  v-if="course.teachers!=null&&course.teachers.length>=3">
                                            <div class="head3">...</div>
                                            <img :src="QiNiuPath+course.teachers[0].pic" style="margin-left: 10px;">
                                            <img :src="QiNiuPath+course.teachers[1].pic" style="position: absolute;right: 30px;">
                                            <img :src="QiNiuPath+course.teachers[2].pic" style="position: absolute;margin-left: 10px;">
                                        </div>
                                        <h2 ><span v-if="course.teachers!=null&&course.teachers.length>0">{{course.teachers.length==1?course.teachers[0].teacherName:course.teachers.length==2?course.teachers[0].teacherName+' '+ course.teachers[1].teacherName:course.teachers.length>2?course.teachers[0].teacherName+' '+ course.teachers[1].teacherName+' '+ course.teachers[2].teacherName:''}}</span><br /><span v-if="course.teachers!=null&&course.teachers.length>0"></span></h2>
                                        </div>
                                        <p>{{course.studentCount}}人已加入课程</p>
                                    </li>
                                </ul>
                                <a-pagination showQuickJumper hideOnSinglePage :current="courseCurrentPage" :total="courseTotal"   :page-size.sync="coursePageSize" :showTotal="(courseTotal, range) => `共${courseTotal}条记录 第${courseCurrentPage}/${Math.ceil(courseTotal/coursePageSize)}页`"  @change="handleCourseListChange" />
                            </div>
                            <div v-else-if="courseList.length<=0 && isLogin">
                                <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                            </div> 
                            <Empty v-else-if="!isLogin"></Empty>   
                        </div>
                        <div class="tab_cont" v-if="item.moduleCode == MODELCODE.MEDAL">
                            <div v-if="medalList&&medalList.length>0 && isLogin">
                                <ul class="medal" >
                                    <li v-for='medal in  medalList' :key="medal.id" @click="goMedalDetails(medal.id)">
                                        <img :src="QiNiuPath+medal.excellentIcon">
                                        <p>{{medal.name}}</p>
                                    </li>
                                </ul>
                                <a-pagination showQuickJumper hideOnSinglePage :current="medalCurrentPage" :total="medalTotal"   :page-size.sync="medalPageSize" :showTotal="(medalTotal, range) => `共${medalTotal}条记录 第${medalCurrentPage}/${Math.ceil(medalTotal/medalPageSize)}页`"  @change="handleMedalListChange" />
                            </div>  
                            <div v-else-if="medalList.length<=0 && isLogin">
                                <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                            </div>
                            <Empty v-else-if="!isLogin"></Empty>   
                        </div>
                        <div class="tab_cont" v-if="item.moduleCode == MODELCODE.ACTIVITY">
                            <div v-if="matchList&&matchList.length>0">
                                <div class="race" >
                                    <ul v-for="match in matchList" :key="match.id" @click="goMatchDetails(match.jumpUrl)">
                                        <li><img :src="QiNiuPath+match.matchThumbnail"></li>
                                        <li class="list">
                                            <h2>{{match.matchName}}<img :src="match.status==1?require('../assets/imgs/start_icon.png'):match.status==2?require('../assets/imgs/process_icon.png'):require('../assets/imgs/end_icon.png')"></h2>
                                            <p>{{match.matchInfo}}</p>
                                            <h3><img src="../assets/imgs/calendar_icon.png">比赛时间：{{match.matchStartTime}}-{{match.matchEndTime}}</h3>
                                        </li>
                                    </ul>
                                </div>
                                <a-pagination showQuickJumper hideOnSinglePage :current="matchCurrentPage" :total="matchTotal"   :page-size.sync="matchPageSize" :showTotal="(matchTotal, range) => `共${matchTotal}条记录 第${matchCurrentPage}/${Math.ceil(matchTotal/matchPageSize)}页`"  @change="handleMatchListChange"  />
                            </div> 
                            <div v-else-if="matchList.length<=0">
                                <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                            </div>   
                        </div>
                        <div class="tab_cont" v-if="item.moduleCode == MODELCODE.NEWS">
                            <div  v-if="newsList&&newsList.length>0">
                                <ul class="news">
                                    <li v-for="news in newsList" :key="news.id" @click="goDetails(news.newsId,news.sourceUrl)" >
                                        <h2><img v-if="news.isNew=='0'"  src="../assets/imgs/new_icon.png">{{news.newsTitle}}</h2>
                                        <img :src="QiNiuPath+news.newsPic">
                                        <p>{{news.newsIntroduce}}</p>
                                        <h3>{{news.publishDate}}</h3>
                                    </li>
                                </ul>
                                <a-pagination showQuickJumper hideOnSinglePage :current="newsCurrentPage" :total="newsTotal"   :page-size.sync="newsPageSize" :showTotal="(newsTotal, range) => `共${newsTotal}条记录 第${newsCurrentPage}/${Math.ceil(newsTotal/newsPageSize)}页`"  @change="handleNewsListChange" />
                            </div>
                            <div v-else-if="newsList.length<=0">
                                <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                            </div>      
                        </div>
                    </a-tab-pane>

                    <!-- <a-tab-pane key="1" :tab="'课程('+courseTotal+')'">
                        <div v-if="courseList&&courseList.length>0">
                            <ul class="course" >
                                <li v-for='course in  courseList' :key="course.id" @click="goCourse(course.courseId)">
                                    <h2><span>{{course.difficultyLevel==0?'初级':course.difficultyLevel==1?'中级':'高级'}}</span>{{course.name}}</h2>
                                    <h3>{{course.taskCount}}课时</h3>
                                    <div class="teacher">
                                    <img v-if="course.teachers!=null&&course.teachers.length>0&&course.teachers.length<3" :src="QiNiuPath+course.teachers[0].pic">
                                    <img v-if="course.teachers!=null&&course.teachers.length>1&&course.teachers.length<3" :src="QiNiuPath+course.teachers[1].pic"> 
                                    <div class="headgroup"  v-if="course.teachers!=null&&course.teachers.length>=3">
                                        <div class="head3">...</div>
                                        <img :src="QiNiuPath+course.teachers[0].pic" style="margin-left: 10px;">
                                        <img :src="QiNiuPath+course.teachers[1].pic" style="position: absolute;right: 30px;">
                                        <img :src="QiNiuPath+course.teachers[2].pic" style="position: absolute;margin-left: 10px;">
                                    </div>
                                    <h2 ><span v-if="course.teachers!=null&&course.teachers.length>0">{{course.teachers.length==1?course.teachers[0].teacherName:course.teachers.length==2?course.teachers[0].teacherName+' '+ course.teachers[1].teacherName:course.teachers.length>2?course.teachers[0].teacherName+' '+ course.teachers[1].teacherName+' '+ course.teachers[2].teacherName:''}}</span><br /><span v-if="course.teachers!=null&&course.teachers.length>0"></span></h2>
                                    </div>
                                    <p>{{course.studentCount}}人已加入课程</p>
                                </li>
                            </ul>
                            <a-pagination showQuickJumper hideOnSinglePage :current="courseCurrentPage" :total="courseTotal"   :page-size.sync="coursePageSize" :showTotal="(courseTotal, range) => `共${courseTotal}条记录 第${courseCurrentPage}/${Math.ceil(courseTotal/coursePageSize)}页`"  @change="handleCourseListChange" />
                        </div>
                        <div v-else-if="courseList.length<=0">
                            <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                        </div>    
                    </a-tab-pane>
                    <a-tab-pane key="2" :tab="'勋章('+medalTotal+')'">
                        <div v-if="medalList&&medalList.length>0">
                            <ul class="medal" >
                                <li v-for='medal in  medalList' :key="medal.id" @click="goMedalDetails(medal.id)">
                                    <img :src="QiNiuPath+medal.excellentIcon">
                                    <p>{{medal.name}}</p>
                                </li>
                            </ul>
                            <a-pagination showQuickJumper hideOnSinglePage :current="medalCurrentPage" :total="medalTotal"   :page-size.sync="medalPageSize" :showTotal="(medalTotal, range) => `共${medalTotal}条记录 第${medalCurrentPage}/${Math.ceil(medalTotal/medalPageSize)}页`"  @change="handleMedalListChange" />
                        </div>  
                        <div v-else-if="medalList.length<=0">
                            <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" :tab="'赛事('+matchTotal+')'">
                        <div v-if="matchList&&matchList.length>0">
                            <div class="race" >
                                <ul v-for="match in matchList" :key="match.id" @click="goMatchDetails(match.jumpUrl)">
                                    <li><img :src="QiNiuPath+match.matchLogo"></li>
                                    <li class="list">
                                        <h2>{{match.matchName}}<img :src="match.status==1?require('../assets/imgs/start_icon.png'):match.status==2?require('../assets/imgs/process_icon.png'):require('../assets/imgs/end_icon.png')"></h2>
                                        <p>{{match.matchInfo}}</p>
                                        <h3><img src="../assets/imgs/calendar_icon.png">比赛时间：{{match.matchStartTime}}-{{match.matchEndTime}}</h3>
                                    </li>
                                </ul>
                            </div>
                            <a-pagination showQuickJumper hideOnSinglePage :current="matchCurrentPage" :total="matchTotal"   :page-size.sync="matchPageSize" :showTotal="(matchTotal, range) => `共${matchTotal}条记录 第${matchCurrentPage}/${Math.ceil(matchTotal/matchPageSize)}页`"  @change="handleMatchListChange"  />
                        </div> 
                        <div v-else-if="matchList.length<=0">
                            <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                        </div>   
                    </a-tab-pane>
                    <a-tab-pane key="4" :tab="'资讯('+newsTotal+')'">
                        <div  v-if="newsList&&newsList.length>0">
                            <ul class="news">
                                <li v-for="news in newsList" :key="news.id" @click="goDetails(news.newsId,news.sourceUrl)" >
                                    <h2><img v-if="news.isNew=='0'"  src="../assets/imgs/new_icon.png">{{news.newsTitle}}</h2>
                                    <img :src="QiNiuPath+news.newsPic">
                                    <p>{{news.newsIntroduce}}</p>
                                    <h3>{{news.publishDate}}</h3>
                                </li>
                            </ul>
                            <a-pagination showQuickJumper hideOnSinglePage :current="newsCurrentPage" :total="newsTotal"   :page-size.sync="newsPageSize" :showTotal="(newsTotal, range) => `共${newsTotal}条记录 第${newsCurrentPage}/${Math.ceil(newsTotal/newsPageSize)}页`"  @change="handleNewsListChange" />
                        </div>
                        <div v-else-if="newsList.length<=0">
                            <no-data promptText="提示暂无相关结果，请换个关键词试试吧"/>
                        </div>      
                    </a-tab-pane> -->
                </a-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { queryCoursePage, queryMatchPage, queryMedalPage, queryNewsPage } from '@/apis/CommonSerach.js';
    import {queryMatchDetailUrl } from '@/apis/EventCenter.js';
    import {queryUsers} from '@/assets/js/queryUsers.js';
    import NoData from '@/pages/NoData.vue';
    import Empty from '@/components/common/empty.vue';

    export default {
        name: 'Search',
        data() {
            return {
                coursePageSize: 12,
                courseCurrentPage: 1,
                courseTotal: 0,
                courseList: [],
                medalPageSize: 25,
                medalCurrentPage: 1,
                medalTotal: 0,
                medalList: [],
                matchPageSize: 10,
                matchCurrentPage: 1,
                matchTotal: 0,
                matchList: [],
                newsPageSize: 10,
                newsCurrentPage: 1,
                newsTotal: 0,
                newsList: [],
                activeKey: 0,
                SKeyWord: '',

                searchMenuList: [
                    {
                        moduleCode: "offlineClasses",
                        moduleNameUser: "课程中心",
                        total: 0,
                    },
                    {
                        moduleCode: "News",
                        moduleNameUser: "新闻资讯",
                        total: 0,
                    },
                    {
                        moduleCode: "medal",
                        moduleNameUser: "微认证",
                        total: 0,
                    },
                    {
                        moduleCode: "Event_Center",
                        moduleNameUser: "活动中心",
                        total: 0,
                    },
                ],
                isLogin: false, // 是否登录
            }
        },
        components:{
            'NoData':NoData,
            Empty
        },
        computed: {
            ...mapState({
                proToken: state => state.indexStore.B_PRO_TOKEN,
                QiNiuPath: state => state.indexStore.QiNiuPath, //七牛云
                KeyWord: state => state.indexStore.KeyWord, // 关键词
                PortalConfig: state => state.indexStore.PortalConfig, // 导航配置
                MODELCODE: state => state.indexStore.MODELCODE, //模块名称
            }),

        },
        created() {
            this.SKeyWord = this.KeyWord;
            this.getTabsNum();
        },
        mounted() {

        },
        watch: {
            KeyWord(e) {
                let that = this;
                that.SKeyWord=e;
                that.searchData();
            },
        },
        //路由更新时触发
        //beforeUpdate(to, from, next) {
        //    let that = this;
        //    that.searchData();
        //},
        methods: {
            searchData() {
                this.courseCurrentPage = 1;
                this.medalCurrentPage = 1;
                this.matchCurrentPage = 1;
                this.newsCurrentPage = 1;
                this.getTabsNum();
            },
            //enter
            enter(e) {
                const that = this;
                if (e.keyCode == 13) {
                    that.courseCurrentPage = 1;
                    that.medalCurrentPage = 1;
                    that.matchCurrentPage = 1;
                    that.newsCurrentPage = 1;
                    that.getTabsNum();
                }
                if (e.keyCode == 46) {
                    that.SKeyWord ='';
                }
            },
            //课程分页查询
            GetCourseList() {
                let that = this;

                let params = {
                    keyWord: that.SKeyWord,
                    currentPage: that.courseCurrentPage,
                    pageSize: that.coursePageSize

                };
                return new Promise((resolve) => {
                    that.$nextTick(() => {
                        queryCoursePage(params).then(res => {
                            let msg = res.data != undefined && res.data != null ? res.data : null;
                            if (msg != null && msg.status == 0) {
                                
                                that.courseTotal = msg.result ? msg.result.total : 0;
                                that.courseList = msg.result && msg.result.courseList ? msg.result.courseList.map(function (item) {
                                    return {
                                        name: item.name,
                                        courseId: item.courseId,
                                        difficultyLevel: item.difficultyLevel,
                                        taskCount: item.taskCount,
                                        studentCount: item.studentCount,
                                        teachers: item.teachers ? item.teachers.map(function (t) {
                                            return {
                                                teacherName: t.teacherName,
                                                pic: t.pic
                                            }
                                        }) : []
                                    }
                                }) : [];
                                resolve(msg.result.total);

                                this.PortalConfig.searchMenuList.forEach(e => {
                                    if(e.moduleCode == 'offlineClasses') {
                                        e.total = msg.result ? msg.result.total : 0;
                                    }
                                })
                            } else {

                            }
                        })
                    })
                })
                
            },
            //课程分页事件
            handleCourseListChange(page) {
                let that = this;
                that.courseCurrentPage = page;
                that.GetCourseList();
            },

            //勋章查询
            GetMedalList(resolve) {

                let that = this;

                let params = {
                    keyWord: that.SKeyWord,
                    currentPage: that.medalCurrentPage,
                    pageSize: that.medalPageSize

                };
                return new Promise((resolve) => {
                    that.$nextTick(() => {
                        queryMedalPage(params).then(res => {
                            let msg = res.data != undefined && res.data != null ? res.data : null;
                            if (msg != null && msg.status == 0) {
                                
                                that.medalTotal = msg.result ? msg.result.total : 0;
                                that.medalList = msg.result && msg.result.medalList ? msg.result.medalList.map(function (item) {
                                    return {
                                        name: item.name,
                                        id: item.id,
                                        notObtainedIcon: item.notObtainedIcon,
                                        obtainedIcon: item.obtainedIcon,
                                        goodIcon: item.goodIcon,
                                        excellentIcon: item.excellentIcon
                                    }
                                }) : [];
                                resolve(msg.result.total)

                                this.PortalConfig.searchMenuList.forEach(e => {
                                    if(e.moduleCode == 'medal') {
                                        e.total = msg.result ? msg.result.total : 0;
                                    }
                                })

                            } else {

                            }
                        })
                    })
                })
            },

            //勋章分页事件
            handleMedalListChange(page) {
                let that = this;
                that.medalCurrentPage = page;
                that.GetMedalList();
            },

            //查询赛事
            GetMatchList(resolve) {
                let that = this;

                let params = {
                    keyWord: that.SKeyWord,
                    currentPage: that.matchCurrentPage,
                    pageSize: that.matchPageSize
                };

                return new Promise((resolve) => {
                    that.$nextTick(() => {
                        queryMatchPage(params).then(res => {
                            let msg = res.data != undefined && res.data != null ? res.data : null;
                            if (msg != null && msg.status == 0) {
                               
                                that.matchTotal = msg.result ? msg.result.total : 0;
                                that.matchList = msg.result && msg.result.matchList ? msg.result.matchList.map(function (item) {
                                    return {
                                        id: item.matchId,
                                        matchName: item.matchName,
                                        matchStartTime: item.matchStartTime,
                                        matchEndTime: item.matchEndTime,
                                        matchLogo: item.matchLogo,
                                        matchThumbnail: item.matchThumbnail,
                                        matchInfo: item.matchInfo,
                                        isStandard: item.isStandard,
                                        jumpUrl: item.jumpUrl,
                                        status: item.status
                                    }
                                }) : [];
                                 resolve(msg.result.total)
                                 this.PortalConfig.searchMenuList.forEach(e => {
                                    if(e.moduleCode == 'Event_Center') {
                                        e.total = msg.result ? msg.result.total : 0;
                                    }
                                })
                            } else {

                            }
                        })
                    })
                })
            },
            //赛事分页事件
            handleMatchListChange(page) {
                let that = this;
                that.matchCurrentPage = page;
                that.GetMatchList();
            },

            //查询资讯
            GetNewsList(resolve) {
                let that = this;

                let params = {
                    keyWord: that.SKeyWord,
                    currentPage: that.newsCurrentPage,
                    pageSize: that.newsPageSize

                };

                return new Promise((resolve) => {
                    that.$nextTick(() => {
                        queryNewsPage(params).then(res => {
                            let msg = res.data != undefined && res.data != null ? res.data : null;
                            if (msg != null && msg.status == 0) {
                               
                                that.newsTotal = msg.result ? msg.result.total : 0;
                                that.newsList = msg.result && msg.result.newList ? msg.result.newList.map(function (item) {
                                    return {
                                        newsId: item.newsId,
                                        newsTitle: item.newsTitle,
                                        newsIntroduce: item.newsIntroduce,
                                        publishDate: item.publishDate,
                                        newsPic: item.newsPic,
                                        isNew: item.isNew,
                                        sourceUrl: item.sourceUrl
                                    }
                                }) : [];
                                resolve(msg.result.total);
                                that.PortalConfig.searchMenuList.forEach(e => {
                                    if(e.moduleCode == 'News') {
                                        e.total = msg.result ? msg.result.total : 0;
                                    }
                                })
                                // that.
                            } else {

                            }
                        })
                    })
                })
            },

            //资讯分页事件
            handleNewsListChange(page) {
                let that = this;
                that.newsCurrentPage = page;
                that.GetNewsList();
            },
            //课程详情
            goCourse(courseId){
                const that=this;
                that.$router.push({
                    path: '/VOfflineClassesDetail', query: { "id": courseId}
                });
            },
            //资讯详情
            goDetails: function (ID, newSourceUrl) {
                const that = this;
                if (newSourceUrl == null || newSourceUrl == undefined || newSourceUrl == '') {
                    that.$router.push({
                        path: '/VNewsDetails', query: { "NewsId": ID, "NewsSource": 2 }
                    });
                }
                else {
                    window.open(newSourceUrl);
                }
               
            },
            //赛事详情
            goMatchDetails(fccUrl) {
                let that = this;
                window.open(fccUrl); 
                //let params = {
                //    id: matchId,
                //};
                //queryMatchDetailUrl(params).then(res => {
                //        let msg = res != undefined && res.data != undefined && res.data != null ? res.data : null;
                //        if (msg != null && msg.status == 0 && msg.result!=null) {
                //            window.open(msg.result.fccUrl);
                //        }
                //    })
            },
            //勋章详情
            goMedalDetails(id){
                const that=this;
                that.$router.push({
                    path:"/VMedalDetails",
                    query:{
                        MEDAL_ID:id
                    }
                })
            },

            // 获取分类列表
            getTabsNum() {
                if(!this.proToken){
                    this.isLogin = false;
                    this.GetNewsList();
                    this.GetMatchList();
                }else{
                    this.isLogin = true;
                    this.GetNewsList();
                    this.GetMatchList();
                    this.GetMedalList();
                    this.GetCourseList();
                }
            },

            // 判断是否登录
            checkLogin() {
                if(!this.proToken) {
                    this.isLogin = false;
                }else{
                    this.isLogin = true;
                }
            },
        },
    }
</script>

<style scoped>
#Search{
    width:100%;
    min-height: calc(100vh - 159px);
    max-height:auto;
    overflow-y: hidden;
}
#Search .Main{
    width: 1200px;
    margin:0 auto;
    padding:30px 0px;
}
#Search .Main button{
    background-color: #5D5AED;
    border-color: #5D5AED;
    height: 44px;
    padding: 0px;
    width: 102px;
    border-radius: 24px;
    font-size: 16px;
    margin-left: -100px;
    vertical-align: 0px;
}
#Search .Main button>img{
    width: 18px;
    margin-right: 5px;
    vertical-align: -4px;
}
#Search .ant-input{
    border-radius: 24px;
    height: 40px;
    width: 680px;
    border: none;
    padding: 4px 20px;
}
#Search .ant-input:focus {
   border-color: #5D5AED;
   outline: 0;
   -webkit-box-shadow: 0 0 0 2px rgba(93, 90, 237, 0.2);
   box-shadow: 0 0 0 2px rgba(93, 90, 237, 0.2);
}
#Search .topInput{
    width: 700px;
    margin:0 auto;
}
#Search .content{
    margin-top: 50px;
}
#Search .content .course{
    height: auto;
    overflow-y: hidden;
    margin-left: -10px;
}
/* 课程 */
.course li{
    width:380px;
    height:236px;
    padding:20px;
    background-color: #FFFFFF;
	border-radius: 8px;
    margin: 15px 0px;
    cursor: pointer;
    float: left;
    position: relative;
    margin-left: 20px;
}
.course li:hover{
    -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
	-webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
	box-shadow:0 0 14px rgba(0,0,0,0.1);
}
.course li>h2{
    font-size:22px;
    color: #333A48;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.course li>h2>span{
    background:url(../assets/imgs/grade.png)left no-repeat;
    text-align: center;
    font-size: 16px;
    color: #fff;
    padding: 13px 12px;
    margin-right: 5px;
    vertical-align: 2px;
}
.course li>h3{
    color:#9AA7B9;
    font-size: 14px;
    margin-top: 5px;
}
.course .teacher{
    margin-top: 30px;
}
.course .teacher>img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    float: left;
    margin-right: 8px;
}
.course .teacher>li{
    float: left;
}
.course .teacher>h2{
    font-size: 16px;
    color: #606978;
    margin-left: 10px;
    float: left;
    margin-top: 8px;
}
/* .course .teacher>h2>span{
    color: #9AA7B9;
    font-size: 12px;
} */
.headgroup{
  width: 100px;
  height: auto;
  overflow-y: hidden;
  float: left;
  position: relative;
  margin-left: -10px;
}
.headgroup>img {
  /* float: right !important; */
  margin-left: -10px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.head3 {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #00000061;
  border-radius: 50%;
  z-index: 100;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  right: 0;
  top: 0;
  margin-right:9px;
}
.course>li>p{
  position:absolute;
  bottom: 20px;
  left: 20px;
  font-size: 14px;
  color: #606978;
}
#Search .ant-pagination{
    text-align: center;
    margin: 50px 0px;
}
/* 勋章 */
#Search .content .medal{
    height: auto;
    overflow-y: hidden;
    padding-left: 10px;
}
.medal>li{
    width: 220px;
    height: 220px;
    background-color: #fff;
    float: left;
    text-align: center;
    color: #606978;
    font-size: 16px;
    cursor: pointer;
    margin: 15px 18px 15px 0px;
}
.medal>li:hover{
    -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
	-webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
	box-shadow:0 0 14px rgba(0,0,0,0.1);
}
.medal>li>img{
    width: 150px;
    height: 118px;
    margin-bottom: 15px;
    margin-top: 30px;
}
.medal>li p{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 45px;
}
/* 赛事 */
#Search .content .race{
  padding: 15px;
}
.race>ul{
  overflow-y: hidden;
  padding: 20px;
  height: auto;
  background:rgba(255,255,255,1);
  /* -moz-box-shadow:2px 2px 4px #E9E9EA;
  -webkit-box-shadow:2px 2px 4px #E9E9EA;
  box-shadow:2px 2px 4px #E9E9EA; */ 
  border-radius:6px;
  margin-bottom: 30px;
  cursor: pointer;
}
.race>ul:hover{
    /* box-shadow:3px 5px 14px 0px rgba(0,0,0,0.2); */
    -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
	-webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
	box-shadow:0 0 14px rgba(0,0,0,0.1);
}
.race>ul>li{
   float: left;
}
.race>ul>li>img{
    width: 304px;
    height: 209px;
}
.race .list{
    margin-left: 50px;
    width: 760px;
    position: relative;
    height: 213px;
}
.race .list>h2{
    font-size: 24px;
    color: #333A48;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.race .list>h2>img{
    vertical-align: -2px;
    margin-left: 10px;
}
.race .list>p{
    color: #9AA7B9;
    font-size: 16px;
    line-height:30px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 90px;
} 
.race .list>h3{
    color: #9AA7B9;
    font-size: 16px;
    position:absolute;
    bottom: 0;
    left: 0;
} 
.race .list>h3>img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: -3px;
}
/* 资讯 */
#Search .content .news{
    padding:15px 10px 10px;
    height: auto;
    overflow-y: hidden;
}
.news>li{
  float: left;
  width: 560px;
  background-color: #fff;
  cursor: pointer;
  margin-left: 20px;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 30px;
}
.news>li:hover{
    -moz-box-shadow:0 0 14px rgba(0,0,0,0.1);
	-webkit-box-shadow:0 0 14px rgba(0,0,0,0.1);
	box-shadow:0 0 14px rgba(0,0,0,0.1);
}
.news>li>h2{
  color: #333A48;
  line-height: 35px;
  font-size: 22px;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  overflow:hidden;
  padding-bottom: 20px;
  height: 75px;
}
.news>li>h2>img{
  margin-right: 10px;
  vertical-align: -4px;
  width: 45px;
}
.news>li>img{
  width:170px;
  height: 115px;
  float: left;
}
.news>li>p{
  float: left;
  width: 320px;
  margin-left: 30px;
  line-height: 30px;
  color: #606978;
  font-size: 16px;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
  overflow:hidden;
  margin-top: -3px;
  height: 90px;
  word-wrap: break-word;
}
.news>li>h3{
  margin-left: 30px;
  margin-top: 10px;
  font-size: 14px;
  color: #9AA7B9;
  float: left;
  font-weight: 400;
}
</style>